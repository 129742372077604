<template>
    <div class="container-fluid">
        <PortadaHeader :msj="msj"></PortadaHeader>
        <section class="container mt-5">
            <div class="row">
                <div class="col-12">
                    <div class="row align-items-center">
                        <div class="col-12">
                            <div class="titulo-destacado d-block">
                                <div class="titulos d-block mb-3">
                                    <h2 class="font-weight-bold text-uppercase color-principal">Servicios</h2>
                                    <h6 class="text-muted">La terminal de santamarta ofrece los siguientes servicios</h6>
                                </div>
                                <div class="decoracion">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-5">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-12 mb-4 tarjeta-servicio" v-for="(item, index) of servicios" v-bind:key="index">
                            <div class="d-inline-flex tema-principal mb-3 shadow-lg">
                                <i :class="['h1 m-0', item.icon]"></i>
                            </div>
                            <h5 class="mb-3 color-principal">{{ item.titulo }}</h5>
                            <p class="h6">{{ item.desc }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>