<template src="@/views/servicios/servicios-template.vue"></template>
<style src="@/views/servicios/servicios.css"></style>

<script>
    import PortadaHeader from '@/components/global/PortadaHeader.vue'
    export default {
  name: 'Servicios',
  data: function() {
    return {
        msj: {
            titulo: 'Servicios',
            desc: 'La terminal de santamarta te ofrece los siguientes servicios',
            img: 'foto-1.jpg'
        },
        servicios: [
            {
                icon: 'icofont-dining-table',
                titulo: 'Restaurantes',
                desc: 'En la terminal de Santa Marta, puede encontrar restaurantes, cafeterías y variedades de locales para satisfacer las necesidades del usuario'
            },
            {
                icon: 'icofont-coffee-mug',
                titulo: 'Islas comerciales',
                desc: 'Pequeños establecimientos con agradable atención, en el cual encontrara múltiples productos'
            },
            {
                icon: 'icofont-delivery-time',
                titulo: 'Encomiendas – Giros',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt'
            },
            {
                icon: 'icon-gift',
                titulo: 'Miscelanea',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt'
            },
            {
                icon: 'icon-briefcase',
                titulo: 'Guarda Equipajes',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt'
            },
            {
                icon: 'icon-clock',
                titulo: 'Refresquerías',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt'
            },
            {
                icon: 'icon-office',
                titulo: 'Oficina Tasa de Uso',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt'
            },
            {
                icon: 'icofont-cop ',
                titulo: 'Alcoholimetría',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt'
            },
            {
                icon: 'icon-office',
                titulo: 'Oficina de Gerencia',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt'
            },
            {
                icon: 'icon-office',
                titulo: 'Oficina Administración',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt'
            },
            {
                icon: 'icofont-direction-sign',
                titulo: 'Oficina de atención al turista',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt'
            },
            {
                icon: 'icofont-police',
                titulo: 'Policía Nacional',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt'
            },
            {
                icon: 'icon-man-woman',
                titulo: 'Baños',
                desc: 'Baños publico para damas y caballeros'
            },
        ]
    }
  },
    components: {
        PortadaHeader
    }
}
</script>